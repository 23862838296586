import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: import.meta.env.VITE_SENTRY_RELEASE,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampler: ({ name, attributes, parentSampled }) => {
        if (typeof parentSampled === "boolean") {
            return parentSampled;
        }

        return import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE;
    },
    profilesSampleRate: import.meta.env.VITE_SENTRY_PROFILES_SAMPLE_RATE,
});

const feedbackButton = document.getElementById("onyx-feedback-button");
if (feedbackButton) {
    const feedback = Sentry.feedbackIntegration({
        autoInject: false,
        colorScheme: "light",
        triggerLabel: "Feedback",
        formTitle: "ONYX Feedback",
        submitButtonLabel: "Send Feedback",
        messagePlaceholder: "Request a feature or report an issue.",
        successMessageText: "Thank you for your feedback!",
        showBranding: false,
    });
    feedback.attachTo(feedbackButton);
}
